<template>
  <div class="app-container">
    <div class="left_box">
      <GroupCard v-if="groupInfo !== null" :groupInfo="groupInfo" />
      <div class="club_box">
        成员(
        <span>{{ total }}</span>
        )
      </div>
      <div
        class="bottom_box"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        infinite-scroll-distance="80px"
      >
        <BusinessCard
          :memberList="memberList"
          @reciverBusiness="reciverBusiness"
        />
        <p v-if="loading" style="textAlign: center;">加载中...</p>
        <p v-if="noMore" style="textAlign: center;">没有更多了</p>
      </div>
    </div>
    <div
      class="right_box"
      v-infinite-scroll="messageLoad"
      infinite-scroll-disabled="disabled2"
      infinite-scroll-distance="80px"
    >
      <SingleMessage
        v-for="item in messageList"
        :key="item.id"
        :singleInfo="item"
      />
      <p v-if="loading2" style="textAlign: center;">加载中...</p>
      <p v-if="noMore2" style="textAlign: center;">没有更多了</p>
    </div>
  </div>
</template>

<script>
import { addTabs } from '@/assets/js/addTabs'
import { getSingleGroupInfo, getMembers, getMessageList } from '@/api/whatsApp'
import GroupCard from './components/GroupCard.vue'
import BusinessCard from './components/BusinessCard.vue'
import SingleMessage from '../search/componentens/SingleMessage.vue'
export default {
  name: 'GroupDetail',
  components: {
    GroupCard,
    BusinessCard,
    SingleMessage
  },
  data() {
    return {
      groupInfo: null,
      memberList: [],
      messageList: [],
      // 成员
      total: 0,
      loading: false,
      page_index: 1,
      // 消息
      pageIndex: 1,
      loading2: false,
      total2: 0,
      authorId: null
    }
  },
  async created() {
    await this.getGroupInfo(this.$route.params.groupId)
    this.getMembersList()
    this.getMessageListInfo()
  },
  computed: {
    noMore() {
      return this.memberList.length >= this.total
    },
    disabled() {
      return this.loading || this.noMore
    },
    noMore2() {
      return this.messageList.length >= this.total2
    },
    disabled2() {
      return this.loading2 || this.noMore2
    }
  },
  methods: {
    async getGroupInfo(id) {
      const that = this
      const res = await getSingleGroupInfo(id)
      this.groupInfo = Object.assign({}, res)
      addTabs(that, res.name, res.id, '/group/detail/')
    },
    // 获得群成员
    async getMembersList() {
      const data = {
        group: this.$route.params.groupId,
        page_index: this.page_index
      }
      this.loading = true
      const res = await getMembers(data)
      this.loading = false
      if (res.items.length < res.page_size) {
        this.disabled = true
      }
      this.memberList = [...this.memberList, ...res.items]
      this.total = res.count
    },
    load() {
      this.page_index += 1
      this.getMembersList()
    },
    // 获得消息
    messageLoad() {
      this.pageIndex += 1
      this.getMessageListInfo()
    },
    async getMessageListInfo() {
      const data = {
        group: this.$route.params.groupId,
        page_index: this.pageIndex,
        author: this.authorId
      }
      const res = await getMessageList(data)
      if (res.items.length < res.page_size) {
        this.disabled2 = true
      }
      this.messageList = [...this.messageList, ...res.items]

      this.total2 = res.count
    },
    // 群成员消息筛选
    reciverBusiness(val) {
      if (val) {
        this.pageIndex = 1
        this.messageList = []
        this.authorId = val
        this.getMessageListInfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  justify-content: space-between;
  .left_box {
    width: 300px;
    height: calc(100vh - 120px);
    padding: 10px;
    overflow: hidden;
    // overflow-y: auto;
    margin-top: 10px;
    border: 1px solid #e5e5e5;

    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;

    .club_box {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 10px;
      span {
        color: #ff5400;
      }
    }
    .bottom_box {
      height: calc(100% - 340px);
      // background: pink;
      padding: 10px 0;
      overflow-y: auto;
    }
  }

  .right_box {
    width: calc(100% - 300px);
    height: calc(100vh - 120px);
    margin: 10px 0 0 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    .nomore_box {
      text-align: center;
      font-size: 14px;
      color: #949698;
    }
  }
}
</style>
